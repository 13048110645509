import React from "react";
import {Paper} from "@material-ui/core";
import EnhancedTable from "components/DataTable/EnhancedTable";
import {VideoLabel} from "@material-ui/icons";
import {useIntl} from "react-intl";

export default function LabelList(props) {
    const intl = useIntl();
    const columns = [
        {title: intl.formatMessage({id:'labels.list.column.title', defaultMessage:'Title'}), field: 'title'},
        {
            title: intl.formatMessage({id: 'labels.list.column.entity_type', defaultMessage: 'Entity type'}),
            field: 'entity_type.title',
        },
    ];

    return (
      <Paper>
        <EnhancedTable
          title={intl.formatMessage({id:'labels.list.table.heading', defaultMessage:"Labels list"})}
          columns={columns}
          query="labels"
          mutations="label"
          fields='id title entity_type{title}'
          icon={<VideoLabel/>}
          urlState
          {...props}
        />
      </Paper>
    );
}
