import {Chip, Paper} from "@material-ui/core";
import {Domain, OpenInBrowser} from "@material-ui/icons";
import EnhancedTable from "components/DataTable/EnhancedTable";
import React from "react";
import {useIntl} from "react-intl";

export default function SiteList(props) {
  const intl = useIntl();

  const columns = [
    {
      title: intl.formatMessage({
        id: "sites.list.column.name",
        defaultMessage: "Name",
      }),
      field: "name",
    },
    {
      title: intl.formatMessage({
        id: "sites.list.column.is_active",
        defaultMessage: "Is Active",
      }),
      field: "is_active",
      type: "boolean",
      initial: false,
    },
    {
      title: intl.formatMessage({
        id: "sites.list.column.schema",
        defaultMessage: "Schema",
      }),
      field: "schema",
    },
    {
      title: intl.formatMessage({
        id: "sites.list.column.subscriptions",
        defaultMessage: "Subscriptions",
      }),
      field: "package",
      render: row => row.subscriptions.map(s =>
        <Chip
          style={{marginRight: 6}}
          size="small"
          key={"site-" + row.id + "-sub-" + s.subscription_id}
          label={s.type_title + ': ' + s.subscription_title}
        />)
    },
    {
      title: intl.formatMessage({
        id: "sites.list.column.subscriptions_renewed",
        defaultMessage: "Subscriptions renewed",
      }),
      field: "package",
      render: row => row.subscriptions.map(s =>
        <Chip
          style={{marginRight: 6}}
          size="small"
          key={"site-" + row.id + "-sub-" + s.subscription_id}
          label={s.type_title + ': ' + intl.formatDate(s.renewed_at)}
        />)
    },
    {
      title: intl.formatMessage({
        id: "sites.list.column.created",
        defaultMessage: "Created",
      }),
      field: "created_at",
      type: "datetime",
    },
  ];

  return (
    <Paper>
      <EnhancedTable
        {...props}
        title={intl.formatMessage({
          id: "sites.list.table.heading",
          defaultMessage: "Sites list",
        })}
        columns={columns}
        query="sites"
        mutations="site"
        fields="id reference name is_active schema package demo_expire_at created_at domains{name is_active} subscriptions{subscription_id type_title subscription_title renewed_at}"
        icon={<Domain/>}
        actions={[
          {
            icon: OpenInBrowser,
            tooltip: "Goto",
            onClick: (row) => {
              if (row.domains) {
                let dom = row.domains.find(d => d.is_active);
                if (dom) {
                  if (dom.name.endsWith('localhost')) window.open('http://' + dom.name + ':3000', '_blank');
                  else window.open('https://' + dom.name, '_blank');
                }
              } else {
                alert('No domain was found');
              }
            }
          }
        ]}
        urlState
      />
    </Paper>
  );
}
