import List from "@material-ui/core/List";
import Box from "@material-ui/core/Box";
import React from "react";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import componentStyles from "assets/theme/components/admin-footer.js";

const useStyles = makeStyles(componentStyles);

export default function FooterLinks({width = "100%", ml = 0}) {
  const classes = useStyles();
  return <Grid container classes={{root: classes.justifyContentCenter}}
               style={{width: width, paddingLeft: ml + 16, paddingRight: 16}}>
    <Grid
      item
      xs={12}
      component={Box}
      display="flex"
      style={{justifyContent: "flex-end"}}
    >
      <Box
        component={List}
        display="flex"
        justifyContent="center"
        alignItems="center"
        className={classes.flexDirectionColumn}
      >
        <div className={classes.copyrightWrapper}>
          © {new Date().getFullYear()}{" "}
          <a
            className={classes.copyrightLink}
            href="https://opendims.com"
            rel="noopener noreferrer"
            target="_blank"
          >
            OpenDIMS ApS
          </a>
        </div>
      </Box>
    </Grid>
  </Grid>;
}
