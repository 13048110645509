import React, {useContext, useEffect, useState} from "react";
import {FormControl, Grid, IconButton, InputLabel, LinearProgress} from "@material-ui/core";
import {LanguageContext} from "../../../../contexts/Layouts";
import {EntityContext} from "../../EntityLayoutEditor";
import {getFieldLabel} from "./Field";
import {graphQLApi} from "../../../../services/GraphQLApi";
import {useAuthDispatch} from "../../../../contexts/Auth";
import {makeStyles} from "@material-ui/core/styles";
import theme from "../../../../assets/theme/theme";
import {ArrowDropDown, ArrowDropUp, Check} from "@material-ui/icons";
import Box from "@material-ui/core/Box";
import {useIntl} from "react-intl";
import {booleanFieldTypes, decimalFieldTypes, FieldTypes, stringFieldTypes} from "../../../../variables/fields";

const useStyles = makeStyles((theme) => ({
  progressCompleted: {
    background: 'yellow',
    backgroundColor: theme.palette.success.dark,
  }
}));

export default function CompletionField({field}) {
  const intl = useIntl();
  const {language} = useContext(LanguageContext);
  const {getValuesOfField} = useContext(EntityContext);
  const classes = useStyles();
  //let value = getValuesOfField(field.id)?.pop()?.decimal || 0;
  const [showFields, setShowFields] = useState(false);

  const [compFields, setCompFields] = useState([]);
  const client = new graphQLApi(useAuthDispatch());
  useEffect(() => {
    client.query('{fields(filter:{id:' + field.id + '}){data{id computation_fields{id name type}}}}').then(r => {
      if (r.hasOwnProperty('fields') && r.fields.data[0]) {
        setCompFields(r.fields.data[0].computation_fields || []);
      }
    });
  }, []);
  let values = [];
  compFields.forEach(f => getValuesOfField(f.id).forEach(v => values.push(v)));
  const hasValue = (fieldId) => {
    let f = compFields.find(f => f.id === fieldId);
    for (let i in values) {
      if (values[i].field?.id === fieldId || values[i].field_id === fieldId) {
        // if (f?.type.match(/^entities_\d+$/)) {
        //   console.log(f?.name, f?.type, values.filter(v => (v.field?.id === fieldId || v.field_id === fieldId) && (v.related_entity || v.related_entity_id)));
        // }
        return !(
          (booleanFieldTypes.includes(f?.type) && !values[i].bool)
          || (f?.type === FieldTypes.DATE && !values[i].date?.length)
          || (f?.type === FieldTypes.DATETIME && !values[i].datetime?.length)
          || (f?.type === FieldTypes.NUMBER && (values[i].integer === "" ||values[i].integer === null || values[i].integer === undefined))
          || (decimalFieldTypes.includes(f?.type) && (values[i].decimal === "" || values[i].decimal === null || values[i].decimal === undefined))
          || (stringFieldTypes.includes(f?.type) && !(values[i].string?.length || values[i].text?.length))
          || (f?.type.match(/^entity_\d+$/) && !(values[i].related_entity || values[i].related_entity_id))
          || (f?.type.match(/^entities_\d+$/)
            && !values.filter(v => (v.field?.id === fieldId || v.field_id === fieldId) && v.related_entity).length)
          || (f?.type === FieldTypes.ASSET && !values[i].string?.length)
          || (f?.type === FieldTypes.ASSETS
            && !values.filter(v => (v.field?.id === fieldId || v.field_id === fieldId) && v.string?.length).length)
          || (f?.type === FieldTypes.OPTION && !(values[i].field_option || values[i].field_option_id))
          || (f?.type === FieldTypes.OPTIONS
            && !values.filter(v => (v.field?.id === fieldId || v.field_id === fieldId) && (v.field_option || v.field_option_id)).length)
        ); // return false as if not having a value, if the value is boolean and false.
      }
    }
    return false;
  }
  let value = Math.round((compFields.filter(cf => hasValue(cf.id)).length / compFields.length * 100) || 0.0);

  return <FormControl
    fullWidth
    style={{cursor: "pointer"}}
    onClick={() => setShowFields(!showFields)}
    title={intl.formatMessage({
      id: "entity.edit.fields.completion.clickable",
      defaultMessage: "Click here to see the fields being checked and their statuses"
    })}
  >
    <InputLabel shrink>{getFieldLabel(field, language)}</InputLabel>
    <Box display="flex" style={{gap: 8}} width="100%" alignItems="flex-start">
      <Box style={{flexGrow: 1, paddingTop: 24}}>
        <LinearProgress
          classes={{barColorPrimary: value >= 100 && classes.progressCompleted}}
          variant="determinate"
          value={value}
        />
      </Box>
      <Box display="flex" pt={1.5}>
        <Box style={{
          flexGrow: 1,
          paddingTop: 6,
          color: value >= 100 && theme.palette.success.dark
        }}>{Math.round(value)}%</Box>
        <IconButton size="small">{showFields ? <ArrowDropUp/> : <ArrowDropDown/>}</IconButton>
      </Box>
    </Box>
    {showFields && <Grid item style={{width: "100%"}}>{compFields?.map(f =>
      <Grid
        container
        key={'completeion_of_' + field.id + '_on_field_' + f.id}
        style={{color: hasValue(f.id) ? theme.palette.success.dark : 'inherit'}}
      >
        <Grid item style={{width: 30}}>{hasValue(f.id) && <Check fontSize={"small"}/>}</Grid>
        <Grid item style={{flexGrow: 1}}>{f.name}</Grid>
      </Grid>)}
    </Grid>}
  </FormControl>;
}
