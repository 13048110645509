//TODO: Refactor calculated into computation
//TODO: Implement fields: `Value in computation` & `Language`
import React, {useEffect, useRef, useState} from 'react';
import {useIntl} from 'react-intl';

import {graphQLApi, graphQLReduceFields} from 'services/GraphQLApi';
import {authUser, useAuthDispatch} from 'contexts/Auth';
import EditForm from 'components/Form/EditForm';
import {
  FormControlLabel,
  Grid,
  InputAdornment,
  ListSubheader,
  MenuItem,
  Snackbar,
  Switch,
  TextField,
  Typography
} from '@material-ui/core';
import FieldOptions from './FieldOptions';
import ComputationFields from './ComputationFields';
import {FieldTypes} from "../../variables/fields";
import {useLocation, useNavigate} from "react-router-dom";

//constants
const DEFAULT_LANGUAGE_ID = '0';

export default function Field(props) {
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    id,
    onSaved,
    onChange,
    buttons,
    entityType = null,
    returnedFields = "id"
  } = props;
  const [notification, setNotification] = useState({message: '', show: false});
  const [fieldDefinitions, setFieldDefinitions] = useState([{id: '-', name: '...'}]);
  const fieldsArray = [
    {
      column: 1,
      field: 'name',
      initial: '',
      type: 'String',
      label: intl.formatMessage({id: 'fields.edit.label.name', defaultMessage: 'Name'}),
      input: 'text',
    },
    {
      column: 1,
      field: 'entity_type_id',
      initial: entityType,
      hidden: !!entityType,
      type: 'ID',
      query: 'entityTypes',
      titleField: 'title',
      label: intl.formatMessage({id: 'fields.edit.label.entity_type', defaultMessage: 'Data type'}),
    },
    {
      column: 1,
      field: 'type',
      initial: '',
      type: 'String',
      label: intl.formatMessage({id: 'fields.edit.label.type', defaultMessage: 'Type'}),
      options: [{id: '-', name: '...'}],
      disabled: d => d.usage_count > 0,
      onChange: (value, data, setData) => {
        let d = {...data};
        d.type = value;
        if (data.is_searchable && ![FieldTypes.STRING, FieldTypes.TEXT, FieldTypes.HTML, FieldTypes.OPTION, FieldTypes.OPTIONS].includes(value)) {
          d.is_searchable = false;
        }
        if (data.is_editable && ![FieldTypes.STRING, FieldTypes.TEXT].includes(value)) {
          d.is_editable = false;
        }
        if (FieldTypes.BOOLEAN === value) {
          d.default_value = '0';
        }
        setData(d);
      }
    },
    {
      column: 2,
      field: 'default_value',
      initial: '',
      type: 'String',
      label: 'default_value',
      render: () => <Grid container spacing={2}>
        <Grid item xs={12}><TextField fullWidth disabled label={intl.formatMessage({
          id: 'fields.edit.label.default_value',
          defaultMessage: 'Default value'
        })} select value={'simple'}><MenuItem value={"simple"}>{intl.formatMessage({
          id: 'fields.edit.label.default_value_simple',
          defaultMessage: 'Specify your own default value'
        })}</MenuItem></TextField></Grid>
        <Grid item xs={12}><TextField fullWidth disabled label={intl.formatMessage({
          id: 'fields.edit.label.default_value_simple_input',
          defaultMessage: 'Enter the default value'
        })}/></Grid>
      </Grid>,
    },
    {
      column: 3,
      field: 'uses_languages',
      initial: false,
      type: 'Boolean',
      label: intl.formatMessage({id: 'fields.edit.label.uses_languages', defaultMessage: 'Use language layer'}),
      input: 'switch',
      disabled: d => ![FieldTypes.STRING, FieldTypes.TEXT, FieldTypes.HTML, FieldTypes.OPTION, FieldTypes.OPTIONS].includes(d.type),
    },
    {
      column: 3,
      field: 'is_searchable',
      initial: false,
      type: 'Boolean',
      label: intl.formatMessage({id: 'fields.edit.label.is_searchable', defaultMessage: 'Values are searchable'}),
      input: 'switch',
      disabled: d => ![FieldTypes.STRING, FieldTypes.TEXT, FieldTypes.HTML, FieldTypes.OPTION, FieldTypes.OPTIONS].includes(d.type),
    },
    {
      column: 3,
      field: 'is_editable',
      initial: false,
      type: 'Boolean',
      label: intl.formatMessage({
        id: 'fields.edit.label.is_editable',
        defaultMessage: 'Values are editable in lists'
      }),
      input: 'switch',
      disabled: d => ![FieldTypes.STRING, FieldTypes.TEXT].includes(d.type),
    },
    {
      column: 3,
      field: 'computation_value',
      initial: '',
      type: 'String',
      label: intl.formatMessage({id: 'fields.edit.label.computation_value', defaultMessage: 'Computation Value'}),
      input: 'text',
      disabled: d => !d.type.includes('computation'),
    },
    {
      column: 3,
      field: 'computation_language_id',
      initial: '',
      type: 'Int',
      label: intl.formatMessage({
        id: 'fields.edit.label.language',
        defaultMessage: 'What language should be used?'
      }),
      options: [{id: '-', name: '...'}],
      disabled: d => {
        return !(d.type.includes('computation') && d.uses_languages);
      },
    },
  ];
  if (authUser().hasRole('sysdev')) {
    fieldsArray.push({
      column: 3,
      field: 'is_locked',
      initial: false,
      type: 'Boolean',
      label: intl.formatMessage({id: 'fields.edit.label.is_locked', defaultMessage: 'Field is locked'}),
      input: 'switch',
    });
  }
  const [fields, setFields] = useState(fieldsArray);

  function filterFields(fields) {
    return fields.filter(field => field.field !== 'computation_language_id');
  }

  const [data, setData] = useState({
    ...graphQLReduceFields(fields, 'initial'),
    default_value_type: 'simple',
    default_value_lang: {},
  });
  const handleDataChange = (d, fs, fd, l, ef) => {
    setData({...data, ...d});
    if (!fs) {
      fs = fields;
    }
    if (!fd) {
      fd = fieldDefinitions;
    }
    if (!l) {
      l = languages;
    }
    if (!ef) {
      ef = entityFields;
    }
    setFields(fs.map(field => {
      if (field.field === 'default_value') {
        field.render = (key, data, setData) => <Grid container spacing={2}><Grid item xs={12}>
          {data.type === FieldTypes.BOOLEAN
            ? <FormControlLabel
              control={<Switch
                checked={data.default_value === '1'}
                onChange={e => setData({
                  ...data,
                  default_value: e.target.checked ? '1' : '0'
                })}
              />}
              label={intl.formatMessage({
                id: 'fields.edit.label.default_value_simple_input',
                defaultMessage: 'Enter the default value'
              })}
            />
            : <TextField
              id={"default_value_type"}
              fullWidth
              select
              value={data.default_value_type ? data.default_value_type : null}
              onChange={e => setData({...data, default_value_type: e.target.value})}
              label={intl.formatMessage({
                id: 'fields.edit.label.default_value',
                defaultMessage: 'Default value'
              })}
            >
              <MenuItem value={"simple"}>{intl.formatMessage({
                id: 'fields.edit.label.default_value_simple',
                defaultMessage: 'Specify your own default value'
              })}</MenuItem>
              <ListSubheader>{intl.formatMessage({
                id: 'fields.edit.label.default_value_user',
                defaultMessage: 'Get value from the authenticated user'
              })}</ListSubheader>
              <MenuItem value={"@USER.name"}>{intl.formatMessage({
                id: 'fields.edit.label.default_value_user_name',
                defaultMessage: 'User\'s name'
              })}</MenuItem>
              <MenuItem value={"@USER.title"}>{intl.formatMessage({
                id: 'fields.edit.label.default_value_user_title',
                defaultMessage: 'User\'s title'
              })}</MenuItem>
              <MenuItem value={"@USER.email"}>{intl.formatMessage({
                id: 'fields.edit.label.default_value_user_email',
                defaultMessage: 'User\'s e-mail'
              })}</MenuItem>
              <MenuItem value={"@USER.name_email"}>{intl.formatMessage({
                id: 'fields.edit.label.default_value_user_name_email',
                defaultMessage: 'User\'s name and e-mail'
              })}</MenuItem>
              <ListSubheader disabled>{intl.formatMessage({
                id: 'fields.edit.label.default_value_related',
                defaultMessage: 'Get value from a related entity'
              })}</ListSubheader>{ef.filter(f =>
              f.entity_type.id !== data.entity_type_id?.id
              && f.type === data.type && f.uses_languages === data.uses_languages
            ).map(f =>
              <MenuItem value={"$related_field[" + f.id + "]"}>{intl.formatMessage({
                id: 'fields.edit.label.default_value_related_field',
                defaultMessage: '{entity_type}: {field}'
              }, {entity_type: f.entity_type.title, field: f.name})}</MenuItem>
            )}</TextField>}
        </Grid>
          {data.type !== FieldTypes.BOOLEAN && data.default_value_type === 'simple' && (data.uses_languages
            ? l.map(lang => <Grid item xs={12}><TextField
              name={"default_value_" + lang.locale}
              fullWidth
              InputProps={{
                startAdornment: <InputAdornment position="start">
                  <img alt={lang.name} src={"/flags/" + lang.country_code?.toUpperCase() + ".png"}/>
                </InputAdornment>
              }}
              label={intl.formatMessage({
                id: 'fields.edit.label.default_value_simple_lang',
                defaultMessage: 'Enter the default value for {locale}'
              }, {locale: lang.name})}
              value={data.default_value_lang ? data.default_value_lang[lang.locale] : ''}
              onChange={e => setData({
                ...data,
                default_value_lang: {...data.default_value_lang, [lang.locale]: e.target.value}
              })}
            /></Grid>)
            : <Grid item xs={12}><TextField
              name={"default_value"}
              label={intl.formatMessage({
                id: 'fields.edit.label.default_value_simple_input',
                defaultMessage: 'Enter the default value'
              })}
              fullWidth
              value={data.default_value ? data.default_value : ''}
              onChange={e => setData({
                ...data,
                default_value: e.target.value
              })}
            /></Grid>)}</Grid>;
      } else if (field.field === 'computation_value') {
        const fieldDefinition = fd.find(definition => definition.id === d.type);
        if (fieldDefinition) {
          if (['string', 'field_option_id', 'text', 'html'].includes(fieldDefinition.column)
            || ["computation_same", "computation_different", "computation_search", "computation_is_blank", "computation_is_null"].includes(fieldDefinition.id))
            field.input = 'text';
          else
            field.input = 'number';
          if (fieldDefinition.value_description !== '') {
            field.label = fieldDefinition.value_description;
          } else {
            field.disabled = d => !d.type.includes('computation');
            switch (fieldDefinition.id) {
              case FieldTypes.COMPUTED_SCRIPT_STRING:
              case FieldTypes.COMPUTED_SCRIPT_TEXT:
                field.lines = 4;
                field.label = intl.formatMessage({
                  id: 'fields.edit.label.computation_value_script',
                  defaultMessage: 'Script to perform on creation and when an associated field is changed'
                });
                break;
              case FieldTypes.COMPUTED_BUTTON:
                field.label = intl.formatMessage({
                  id: 'fields.edit.label.computation_value_button',
                  defaultMessage: 'Check for and set this value on click'
                });
                break;
              case FieldTypes.COMPUTED_AUTO_INC:
                field.label = intl.formatMessage({
                  id: 'fields.edit.label.computation_value_auto_inc',
                  defaultMessage: 'Start incrementing from this number'
                });
                break;
              case FieldTypes.COMPUTED_JOIN:
                field.label = intl.formatMessage({
                  id: 'fields.edit.label.computation_value_text_join',
                  defaultMessage: 'Join all values with this string'
                });
                break;
              case FieldTypes.COMPUTED_PREFIX:
                field.label = intl.formatMessage({
                  id: 'fields.edit.label.computation_value_text_prefix',
                  defaultMessage: 'Join all values and prefix it with this string'
                });
                break;
              case FieldTypes.COMPUTED_APPEND:
                field.label = intl.formatMessage({
                  id: 'fields.edit.label.computation_value_text_append',
                  defaultMessage: 'Join all values and append it with this string'
                });
                break;
              case FieldTypes.COMPUTED_SEARCH:
                field.label = intl.formatMessage({
                  id: 'fields.edit.label.computation_value_search',
                  defaultMessage: 'Find this string in the values'
                });
                break;
              case FieldTypes.COMPUTED_SAME:
                field.label = intl.formatMessage({
                  id: 'fields.edit.label.computation_value_same',
                  defaultMessage: 'Is all values same as this string, or leave empty to only check if all values are the same'
                });
                break;
              case FieldTypes.COMPUTED_DIFFERENT:
                field.label = intl.formatMessage({
                  id: 'fields.edit.label.computation_value_different',
                  defaultMessage: 'Is all values same as this string, or leave empty to only check if all values are the same'
                });
                break;
              default:
                field.label = intl.formatMessage({
                  id: 'fields.edit.label.computation_value_number',
                  defaultMessage: 'Use this value in the computation'
                });
            }
          }
        } else {
          field.label = intl.formatMessage({
            id: 'fields.edit.label.computation_value',
            defaultMessage: 'Computation Value'
          });
        }
      }
      return field;
    }));
    if (typeof onChange === 'function') {
      onChange(d);
    }
  };

  const [entityFields, setEntityFields] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const initialValidation = graphQLReduceFields(fields, 'validation');
  const [validation, setValidation] = useState(initialValidation);
  const setValidationFromErrors = (errors) => {
    console.error(errors);
    if (Array.isArray(errors) && errors[0] && errors[0].hasOwnProperty('extensions') && errors[0].extensions.hasOwnProperty('validation')) {

      setValidation({...initialValidation, ...errors[0].extensions.validation});
    }
  };
  const client = new graphQLApi(
    useAuthDispatch(),
    null,
    {handleErrors: setValidationFromErrors},
  );
  useEffect(() => {
    setIsLoading(true);
    let query = 'entityFields: fields{data{id entity_type{id title} type name uses_languages}}\n' +
      'fieldDefinitions{id column title computes value_description}\n' +
      'languages{data{id name locale country_code}}\n' +
      'settings{data{id key data}}\n';
    if (id > 0) {
      query += 'fields(filter:{id: ' + id + '}){ data {id computation_language_id is_locked ' + graphQLReduceFields(filterFields(fields), 'fields') +
        'computation_fields { id name type }' +
        '} }';
    }
    client.query('{' + query + '}').then(r => {
      setIsLoading(false);
      if (r) {
        let newFields = [];
        if (r.hasOwnProperty('entityFields')) {
          setEntityFields(r.entityFields.data);
        }
        if (r.hasOwnProperty('fieldDefinitions') && r.hasOwnProperty('languages') && r.hasOwnProperty('settings')) {
          const settings = {};
          r.settings.data.forEach(s => {
            settings[s.key] = s.data;
          });
          r.languages.data.unshift({
            id: DEFAULT_LANGUAGE_ID,
            locale: settings.locale,
            country_code: settings.country_code,
            name: settings.language
          });
          let langs = [...r.languages.data];
          setLanguages(langs);
          setFieldDefinitions(r.fieldDefinitions);
          r.languages.data.unshift({
            id: 'null',
            locale: 'all',
            country_code: settings.country_code,
            name: <i>{intl.formatMessage({
              id: 'common.option.all_languages',
              defaultMessage: 'All languages'
            })}</i>
          });
          newFields = fields.map(f => {
            if (f.field === 'type') {
              return {...f, options: r.fieldDefinitions.map(f => ({id: f.id, name: f.title}))};
            }
            if (f.field === 'computation_language_id') {
              return {...f, options: r.languages.data};
            }
            return f;
          });
          if (r.hasOwnProperty('fields')) {
            let d = {...r.fields.data[0]};
            if (!authUser().hasRole('sysdev') && d.is_locked) {
              navigate(-1);
              return;
            }
            if (d.default_value?.match(/^@USER\..+$|^\$related_field\[.+]$/)) {
              d.default_value_type = d.default_value;
              d.default_value_lang = {};
              d.default_value = '';
            } else if (d.uses_languages && d.default_value?.match(/^{.*}$/)) {
              d.default_value_type = 'simple';
              d.default_value_lang = JSON.parse(d.default_value);
              d.default_value = '';
            } else if (!d.default_value_type) {
              d.default_value_type = 'simple';
              d.default_value_lang = {};
            }
            handleDataChange({
              ...d,
              computation_value: r.fields.data[0].computation_value || '',
              computation_language_id: r.fields.data[0].computation_language_id ? r.fields.data[0].computation_language_id : 'null',
              entity_type_id: r.fields.data[0].entity_type,
            }, newFields, r.fieldDefinitions, langs, r.entityFields?.data);
          }
          setFields(newFields);
        }
      }
    }).catch(e => console.trace(e));

    return () => {
      setFields(fieldsArray);
      setFieldDefinitions([{id: '-', name: '...'}]);
    };
  }, []);

  const whenSaved = (response) => {
    setIsLoading(false);
    if (typeof onSaved === 'function') {
      onSaved(response);
    } else {
      if (id) {
        navigate(-1);
      } else {
        setNotification({
          message: intl.formatMessage({
            id: 'fields.edit.notification.created',
            defaultMessage: 'Field has been created'
          }), show: true
        });
        if (props.entityType) navigate(-1);
        else navigate(location.pathname.replace('create', response.id), {replace: true});
      }
    }
  };

  const save = () => {
    setIsLoading(true);
    setValidation(initialValidation);

    if (data.type.includes('computation') && computationRef && computationRef.current) {
      if (computationRef.current.hasOwnProperty('setComputationFields')) {
        computationRef.current.setComputationFields();
      }

      if (computationRef.current.hasOwnProperty('fieldsAreCompatible')) {
        if (!computationRef.current.fieldsAreCompatible()) {
          setNotification({
            message: intl.formatMessage({
              id: 'fields.edit.notification.incompatible',
              defaultMessage: 'There are fields with incompatible type in your computation field list'
            }), show: true
          });
          setIsLoading(false);
          return;
        }
      }
    }
    let defVal;
    if (data.default_value_type === 'simple') {
      if (data.uses_languages) {
        defVal = JSON.stringify(data.default_value_lang);
      } else {
        defVal = data.default_value;
      }
    } else {
      defVal = data.default_value_type;
    }
    let query =
      '(' +
      graphQLReduceFields(fields, 'vars_def') +
      ' $computation_fields:[ID]!' +
      ') ' +
      '{ response: ' +
      'fieldCreate(' +
      graphQLReduceFields(fields, 'vars') +
      `, computation_fields:$computation_fields` +
      ') ' +
      '{ ' + returnedFields + ' } }';
    const variables = {
      ...data,
      entity_type_id: data.entity_type_id?.id ? data.entity_type_id.id : data.entity_type_id,
      default_value: defVal,
      computation_fields: data.computation_fields ? data.computation_fields.map(field => field.id) : [],
      computation_value: "" + data.computation_value,
      computation_language_id: data.computation_language_id ? parseInt(data.computation_language_id) : null,
    };
    if (id) {
      variables.id = id;
      // modify query from a create to an update mutation
      query =
        '($id:ID!, ' + query.substr(1).replace('Create(', 'Update(id:$id, ');
    }

    client.mutate(query, variables).then(r => {
      if (r && r.hasOwnProperty('response') && r.response) {
        if (optionsRef && optionsRef.current && optionsRef.current.hasOwnProperty('saveOptions')) {
          optionsRef.current.saveOptions(r.response.id).then(() => {
            whenSaved(r.response);
          });
        } else {
          whenSaved(r.response);
        }
      }
    });
  };

  const optionsRef = useRef();
  const addOptionButton = {
    label: intl.formatMessage({id: 'field_options.button.add', defaultMessage: 'Add option'}),
    onClick: () => {
      if (optionsRef.current
        && optionsRef.current.hasOwnProperty('addOption')
        && typeof optionsRef.current.addOption === 'function') {
        optionsRef.current.addOption();
      }
    },
  };

  const computationRef = useRef();
  const addComputationButton = {
    label: intl.formatMessage({id: 'field_computation.button.add', defaultMessage: 'Add computation'}),
    onClick: () => {
      console.log(computationRef.current, computationRef.current?.addComputation);
      if (computationRef.current && computationRef.current.hasOwnProperty('addComputation') && typeof computationRef.current.addComputation === 'function') {
        computationRef.current.addComputation();
      }
    },
  };

  let formButtons = buttons;

  function addButtons(additionalButtons) {
    if (Array.isArray(formButtons)) {
      if (!formButtons.find(bt => bt.label === additionalButtons.label))
        formButtons.push(additionalButtons);
    } else {
      formButtons = [additionalButtons];
    }
  }

  if (typeof data === 'object') {
    if (data.type === 'option' || data.type === 'options') {
      addButtons(addOptionButton);
      formButtons = formButtons.filter(bt => bt.label !== addComputationButton.label);
    }
    if (data.type.includes('computation')) {
      addButtons(addComputationButton);
      formButtons = formButtons.filter(bt => bt.label !== addOptionButton.label);
    }
  }
  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        message={notification.message}
        open={notification.show}
        onClose={_e => setNotification({...notification, show: false})}
        autoHideDuration={7500}
      />
      <EditForm {...props}
                data={data}
                setData={d => {
                  handleDataChange(d);
                  if (props.setModified) props.setModified(true);
                }}
                query={'fields'}
                mutations={'field'}
                fields={fields.filter(field =>
                  (field.field.substring(0, 12) === 'computation_' && data.type.substring(0, 12) === 'computation_') || field.field.substring(0, 12) !== 'computation_')}
                save={save}
                isLoading={isLoading}
                validation={validation}
                cols={3}
                buttons={formButtons}
                back={props.entityTypeId > 0}
                extraComponent={
                  (typeof data === 'object' && (data.type === 'option' || data.type === 'options')) ?
                    <div style={{marginTop: '13px'}}>
                      <Typography variant="subtitle1"
                                  style={{borderBottom: '1px solid lightgrey'}}><strong>{intl.formatMessage({
                        id: 'field_options.heading',
                        defaultMessage: 'Options',
                      })}</strong></Typography>
                      <FieldOptions field={data} ref={optionsRef}/>
                    </div>
                    : (typeof data === 'object'
                      && data.entity_type_id
                      && data.type.substring(0, 12) === 'computation_'
                      && fieldDefinitions.find(fd => fd.id === data.type)?.computes?.length) ?
                      <div className={{marginTop: '13px'}}>
                        <Typography variant="subtitle1"
                                    style={{borderBottom: '1px solid lightgrey'}}>
                          <strong>{data.type === FieldTypes.COMPUTED_BUTTON ?
                            intl.formatMessage({
                              id: 'field_computation_button.heading',
                              defaultMessage: 'If these fields are not filled then disable the button',
                            }) : [FieldTypes.COMPUTED_SCRIPT_STRING, FieldTypes.COMPUTED_SCRIPT_TEXT].includes(data.type) ?
                              intl.formatMessage({
                                id: 'field_computation_script.heading',
                                defaultMessage: 'Gather the values from these fields and pass them to the script',
                              }) : intl.formatMessage({
                                id: 'field_computation.heading',
                                defaultMessage: 'Computation Fields',
                              })}</strong>
                        </Typography>
                        <ComputationFields field={data} ref={computationRef}/>
                      </div>
                      : <></>
                }
      />
    </>
  );
}
